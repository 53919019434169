import {
  List,
  ListItem,
  Box,
  ListItemIcon,
  ListItemText,
  Button,
  Typography,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { RouteConfig } from '../routes/configs/public';
import serviceRoutes from '../routes/configs/public/services';

const getServiceRoutes = (): RouteConfig[] => {
  const mainRoute = serviceRoutes[0];
  if (!mainRoute?.children) return [];
  return mainRoute.children.filter(route => !route.omitFromContactPage);
};

const ServicesList: React.FC = () => {
  const serviceRoutes = getServiceRoutes();

  if (serviceRoutes.length === 0) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" color="text.secondary">
          No services are currently available.
        </Typography>
      </Box>
    );
  }

  return (
    <List>
      {serviceRoutes.map((route) => {
        const Icon = route.icon;
        const title = route.contactPageLabel || route.seo?.title || '';
        const description = route.seo?.description || '';
        const path = `/services/${route.path}`;

        return (
          <ListItem
            key={route.path}
            alignItems="flex-start"
            sx={{
              flexDirection: 'column',
              mb: 2,
              border: '1px solid #e0e0e0',
              borderRadius: '4px',
              padding: 2,
            }}
          >
            <Box sx={{ display: 'flex', width: '100%', mb: 1 }}>
              {Icon && (
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText
                primary={title}
                secondary={description}
                primaryTypographyProps={{
                  variant: 'h6',
                  component: 'h3',
                  gutterBottom: true,
                }}
                secondaryTypographyProps={{
                  variant: 'body2',
                }}
              />
            </Box>
            <Box sx={{ alignSelf: 'flex-end' }}>
              <Button
                component={RouterLink}
                to={path}
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  borderRadius: '20px',
                  padding: '5px 15px',
                }}
              >
                Learn More
              </Button>
            </Box>
          </ListItem>
        );
      })}
    </List>
  );
};

export default ServicesList;
