import { getRotationDegrees } from './utils/getRotationDegrees';

const MinorTicks = () => {

    const ticks = [];
    for (let tick = 0; tick <= 60; tick++) {
      const radius = 90;
      const degrees = getRotationDegrees('minute', tick);

      if (!(degrees % 30)) {
        continue;
      }

      const angleRadians = (degrees * Math.PI) / 180;
      const yOffset = Math.sin(angleRadians) * radius;
      const xOffset = Math.cos(angleRadians) * radius;

      const outerStyle = {
        top: '50%',
        left: '50%',
        transform: `translate(${xOffset}px, ${yOffset}px) rotate(${degrees}deg)`,
        transformOrigin: '0% 100%',
        zIndex: 5
      };

      const tickStyle = {
        backgroundColor: 'blue',
        height: '1px',
        width: '1px',
      };

      ticks.push(
        <div key={degrees} style={{ position: 'absolute', ...outerStyle }}>
          <div style={{ position: 'absolute', ...tickStyle }}></div>
        </div>
      );
    }

    return <div style={{ position: 'absolute', height: '100%', width: '100%' }}>{ticks}</div>;
};

export default MinorTicks;
