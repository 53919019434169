import React, { useEffect, useState } from 'react';

// Define the map of hero images
const heroImages: { [key: string]: string } = {
  laurie_holding_plant: 'laurie_holding_plant.jpg',
  laurie_home_flipped_cropped: 'laurie_home_flipped_cropped.jpg',
  // Add more images as needed
};

// Define the photo credit type
type PhotoCredit = {
  name: string;
  link?: string;
};

// Define the map of photo credits
const photoCredits: { [key: string]: PhotoCredit } = {
  morgann: {
    name: 'Moments with Morgann',
    link: 'https://www.instagram.com/momentswithmorgann/',
  },
  // Add more credits as needed
};

interface HeroImageProps {
  imageName: keyof typeof heroImages;
  photoCreditKey: keyof typeof photoCredits;
}

const HeroImage: React.FC<HeroImageProps> = ({ imageName, photoCreditKey }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const photoCredit = photoCredits[photoCreditKey];

  useEffect(() => {
    const loadImage = async () => {
      try {
        const imageUrl = new URL(`../assets/${heroImages[imageName]}`, import.meta.url);
        setImageSrc(imageUrl.href);
      } catch (error) {
        console.error(`Error loading image: ${imageName}`, error);
        setImageSrc(null);
      }
    };

    loadImage();
  }, [imageName]);

  if (!imageSrc) return null;

  return (
    <div className="hero-image-container">
      <div
        className="home-hero-image"
        style={{ backgroundImage: `url(${imageSrc})` }}
      />
      <div className="photo-credit">
        Photo by:{' '}
        {photoCredit.link ? (
          <a href={photoCredit.link} target="_blank" rel="noopener noreferrer">
            {photoCredit.name}
          </a>
        ) : (
          photoCredit.name
        )}
      </div>
    </div>
  );
};

export default HeroImage;
