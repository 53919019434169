import { Box, useTheme, useMediaQuery, Theme } from '@mui/material';
import { styled } from '@mui/system';

interface StyledColumnProps {
  isMobile: boolean;
  spaceBetween?: boolean;
}

const StyledColumn = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile' && prop !== 'spaceBetween',
})<StyledColumnProps>(({ theme, isMobile, spaceBetween }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: spaceBetween ? 'flex-start' : 'center',
  width: isMobile ? '100%' : (spaceBetween ? '45%' : '50%'),
}));

const Divider = styled(Box)(({ }) => ({
  width: '1px',
  backgroundColor: '#6C6C6F',
  alignSelf: 'stretch', // This ensures the divider takes up full height
}));

interface TwoColumnGridProps {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  spaceBetween?: boolean;
}

const TwoColumnGrid: React.FC<TwoColumnGridProps> = ({ leftContent, rightContent, spaceBetween = false }) => {
  const theme = useTheme<Theme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: spaceBetween ? 'space-between' : 'center',
        alignItems: 'stretch', // This ensures all children stretch to full height
        width: '100%',
      }}
    >
      <StyledColumn isMobile={isMobile} spaceBetween={spaceBetween}>
        {leftContent}
      </StyledColumn>
      
      {spaceBetween && !isMobile && <Divider />}
      
      <StyledColumn isMobile={isMobile} spaceBetween={spaceBetween}>
        {rightContent}
      </StyledColumn>
    </Box>
  );
};

export default TwoColumnGrid;