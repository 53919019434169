import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Tabs, styled } from '@mui/material';
import Tab, { TabProps } from '@mui/material/Tab';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  color: theme.palette.grey[400],
  '&:hover': {
    color: theme.palette.common.white,
  },
  '&.Mui-selected': {
    color: theme.palette.primary.main,
  },
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '4px',
    backgroundColor: theme.palette.primary.main,
    transform: 'scaleX(0)',
    transition: 'transform 0.3s ease',
  },
  '&.Mui-selected::after': {
    transform: 'scaleX(1)',
  },
}));

interface LinkTabProps extends Omit<TabProps, 'component'> {
  to: string;
  label: string;
}

const LinkTab: React.FC<LinkTabProps> = ({ to, label, ...props }) => {
  return (
    <Link to={to} style={{ textDecoration: 'none' }}>
      <StyledTab label={label} {...props} />
    </Link>
  );
};

const tabRoutes = ['/services/plant-maintenance', '/services/wow-now', '/services/diy'];

const ServicesNavigation: React.FC = () => {
  const location = useLocation();
  const currentTabIndex = tabRoutes.indexOf(location.pathname);
  const [value, setValue] = React.useState(
    currentTabIndex !== -1 ? currentTabIndex : 0
  );

  React.useEffect(() => {
    const index = tabRoutes.indexOf(location.pathname);
    if (index !== -1) {
      setValue(index);
    }
  }, [location.pathname]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StyledAppBar position="static">
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="service tabs"
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <LinkTab label="Plant Maintenance" to="/services/plant-maintenance" value={0} />
        <LinkTab label="WOW Now" to="/services/wow-now" value={1} />
        <LinkTab label="DIY Green Thumb" to="/services/diy" value={2} />
      </Tabs>
    </StyledAppBar>
  );
};

export default ServicesNavigation;
