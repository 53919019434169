import InfoSection from '../../components/StyledGrids/info-section';

export default function WhereYouLive() {
  const infoContent = {
    header: 'Where You Live',
    body: 'If you don’t love it, taking care of plants can feel like just another chore.  We love taking care of plants and have spent a long time learning what works where. Let Redhead Green Thumb bring beauty and life to your home.',
    // buttonText: 'For Home',
    // onButtonClick: () => { navigate('at-home') },
  };
  return <InfoSection {...infoContent} />;
}
