// services.ts
import OpacityIcon from '@mui/icons-material/Opacity';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import BugReportIcon from '@mui/icons-material/BugReport';
import ShieldIcon from '@mui/icons-material/Shield';
import styled from '@emotion/styled';
import { Typography, Grid, Divider, Card, CardContent } from '@mui/material';
import React from 'react';
import { SvgIconComponent } from '@mui/icons-material';

const ColoredTypography = styled(Typography)<{ color: string }>(
  ({ color }) => ({
    color: color,
  })
);

interface PlantService {
  title: string;
  description: string;
  icon: SvgIconComponent;
  color: string;
}

const PlantMaintenanceServices: React.FC = () => {
  const plantServices: PlantService[] = [
    {
      title: 'Regular Watering and Feeding',
      description:
        'Scheduled watering and fertilization to ensure plants remain healthy and lush.',
      icon: OpacityIcon,
      color: 'blue',
    },
    {
      title: 'Pruning and Trimming',
      description:
        'Routine trimming to promote growth and maintain the desired shape and size of plants.',
      icon: ContentCutIcon,
      color: 'secondary', // Assuming secondary is red in your theme
    },
    {
      title: 'Pest and Disease Control',
      description:
        'Monitoring and treating plants to prevent and manage any pest infestations or diseases.',
      icon: BugReportIcon,
      color: 'brown',
    },
    {
      title: 'Greenery Guardian',
      description:
        'Swiftly swapping out any tired plants in your space to keep things fresh and fabulous.',
      icon: ShieldIcon,
      color: 'primary', // Assuming primary is green in your theme
    },
  ];

  return (
    <Grid container>
      {plantServices.map((service: PlantService, index: number) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          
          <Grid item xs={12}>
            <Card elevation={0} sx={{ backgroundColor: 'transparent' }}>
              <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
                <div>
                  <ColoredTypography
                    variant="h6"
                    gutterBottom
                    color={service.color}
                    lineHeight="1em"
                  >
                    <service.icon /> {service.title}
                  </ColoredTypography>
                  <Typography variant="body2" color="text.secondary">
                    {service.description}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default PlantMaintenanceServices;
