import { Box, Typography } from '@mui/material';
import ServicesList from '../components/services-list';

const AboutUs: React.FC = () => {
  return (
    <Box sx={{ maxWidth: 800, margin: 'auto', padding: 3 }}>
      <Typography variant="h1" gutterBottom>
       Redhead Green Thumb
      </Typography>
      <Typography variant="body1" paragraph>
        Welcome to Redhead Green Thumb, your local plant care experts based in the heart of Brookings, South Dakota. Our passion for plants and commitment to creating vibrant, green spaces has been growing strong since our establishment in this thriving community.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Rooted in Brookings, SD
      </Typography>
      <Typography variant="body1" paragraph>
        We're proud to be part of a community that values innovation, education, and quality of life. As home to <a href="https://www.sdstate.edu/" target="_blank" rel="noreferrer">South Dakota State University</a>, Brookings offers a unique blend of small-town charm and big-city opportunities. This dynamic environment has nurtured our business, allowing us to flourish alongside the city's growth and development.
      </Typography>

      <Typography variant="h4" gutterBottom>
        Our Services
      </Typography>
      <ServicesList />

      <Typography variant="h4" gutterBottom>
        Connect With Us
      </Typography>
      <Typography variant="body1" paragraph>
        We love engaging with our community, both in person and online. Follow us on Instagram <a href="https://www.instagram.com/rdhdgreenthmb/" target="_blank" rel="noopener noreferrer">@rdhdgreenthmb</a> and Facebook <a href="https://www.facebook.com/rdhdgreenthmb" target="_blank" rel="noopener noreferrer">@rdhdgreenthmb</a> for plant care tips, local updates, and a glimpse into our green world.
      </Typography>
    </Box>
  );
};

export default AboutUs;