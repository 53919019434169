import { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { getClockHandStyle } from './utils/getClockHandStyle';
import ClockNumbers from './clock-numbers';
import MinorTicks from './minor-ticks';
import ClockPivot from './clock-pivot';


interface AnalogClockProps {
  timeZone?: string;
}

const AnalogClock = ({ timeZone }: AnalogClockProps) => {
  timeZone = timeZone || 'America/Chicago';
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      const currentDate = new Date().toLocaleString('en', {
        timeZone: timeZone,
      });
      setTime(new Date(currentDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeZone]);

  return (
    <Box textAlign="center">
      <Typography variant="h5" gutterBottom>
        Current Time ({timeZone})
      </Typography>
      <div
        style={{
          position: 'relative',
          width: '200px',
          height: '200px',
          margin: 'auto',
          borderRadius: '50%',
          border: 'solid 2px',
        }}
      >
        {/* Render Clock Numbers */}
        <ClockNumbers />

        {/* Render Minor Ticks */}
        <MinorTicks />

        {/* Clock pivot */}
        <ClockPivot />

        {/* Hour Hand */}
        <div
          style={{
            ...getClockHandStyle(time, 'hour'),
          }}
        ></div>

        {/* Minute Hand */}
        <div
          style={{
            ...getClockHandStyle(time, 'minute'),
          }}
        ></div>

        {/* Second Hand */}
        <div
          style={{
            ...getClockHandStyle(time, 'second'),
          }}
        ></div>
      </div>
    </Box>
  );
};

export default AnalogClock;
