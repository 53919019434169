const ClockPivot = () => {
 return <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
            background: 'black',
            transform: 'translate(-50%, -50%)',
            zIndex: 100,
          }}
        ></div>
}

export default ClockPivot;
