import { Navigate } from 'react-router-dom';
import { Home, Contact, About, NotFound } from '../pages';
import PlantMaintenance from '../pages/Services/PlantMaintenance/plant-maintenance';
import Services from '../pages/Services/services';
import WowNow from '../pages/Services/WowNow/wow-now';
import DIYService from '../pages/Services/DIY/diy';
import { SvgIconComponent } from '@mui/icons-material';

export interface RouteConfig {
  path: string;
  element: React.ReactNode;
  seo?: {
    title: string;
    description: string;
    changeFrequency?:
      | 'always'
      | 'hourly'
      | 'daily'
      | 'weekly'
      | 'monthly'
      | 'yearly'
      | 'never';
    priority?: number;
  };
  children?: RouteConfig[];
  omitFromContactPage?: boolean;
  contactPageLabel?: string;
  icon?: SvgIconComponent;
}

const routes: RouteConfig[] = [
  {
    path: '/',
    element: <Home />,
    seo: {
      title: 'Redhead Green Thumb | Home',
      description:
        'Welcome to Redhead Green Thumb - your local plant care experts based in Brookings, SD. We specialize in plant maintenance, kokedamas, rent-to-own plants, and DIY plant care. We bring vibrant green spaces to your home and business. Follow us on Instagram and Facebook for tips and updates!',
      changeFrequency: 'weekly',
      priority: 1.0,  
    },
  },
  {
    path: '/about',
    element: <About />,
    seo: {
      title: 'Redhead Green Thumb | About Us',
      description:
        'Discover Redhead Green Thumb, your local plant care experts based in Brookings, SD. We specialize in plant maintenance, kokedamas, rent-to-own plants, and DIY plant care. We bring vibrant green spaces to your home and business. Follow us on Instagram and Facebook for tips and updates!',
      changeFrequency: 'monthly',
      priority: 0.8,
    },
  },
  {
    path: '/contact',
    element: <Contact />,
    seo: {
      title: 'Redhead Green Thumb | Contact Us',
      description:
        'Get in touch with Redhead Green Thumb for plant care advice and inquiries.',
      changeFrequency: 'monthly',
      priority: 0.8,
    },
  },
  {
    path: '/services',
    element: <Services />,
    seo: {
      title: 'Redhead Green Thumb | About Us',
      description:
        'Discover professional plant maintenance services tailored to keep your indoor plants vibrant and healthy year-round. From regular watering and pruning to pest control and a unique rent-to-own program, we ensure your plant ecosystem thrives. Explore our affordable pricing and DIY options to enhance your space with beautiful greenery.',
      changeFrequency: 'monthly',
      priority: 0.8,
    },
    children: [
      {
        path: '',
        element: <Navigate to="plant-maintenance" replace />,
        omitFromContactPage: true,
      },
      {
        path: 'plant-maintenance',
        element: <PlantMaintenance />,
        seo: {
          title: 'Plant Maintenance | Redhead Green Thumb',
          description:
            'Redhead Green Thumb provides plant maintenance services for businesses and homes. Helping to ensure that your indoor environments are vibrant, healthy, and aesthetically pleasing year-round.',
          changeFrequency: 'monthly',
          priority: 0.9,
        },
        contactPageLabel: 'Plant Maintenance',
      },
      {
        path: 'wow-now',
        element: <WowNow />,
        seo: {
          title: 'Rent to Own Plants | Redhead Green Thumb',
          description:
            'Discover our innovative Rent-to-Own Plants Program at Redhead Green Thumb. Enjoy instant greenery in your space with flexible, affordable monthly payments. Choose from a curated selection of indoor plants, receive expert maintenance, and gradually own your plants. Low deposit, no long-term commitment. Transform your environment today with our unique plant rental service.',
          changeFrequency: 'weekly',
          priority: 0.9,
        },
        contactPageLabel: 'Rent to Own Plants',
      },
      {
        path: 'diy',
        element: <DIYService />,
        seo: {
          title: 'DIY | Redhead Green Thumb',
          description: 'Discover the DIY Plant Care Service at Redhead Green Thumb, where you can learn to select and care for your plants with expert guidance. Our personalized approach helps you choose the perfect plants and containers for your space, providing you with detailed care instructions. Enjoy on-demand consultations for any plant care challenges you may face. Empower yourself to create a thriving indoor garden with our DIY service!',
          changeFrequency: 'weekly',
          priority: 0.9,
        },
        contactPageLabel: 'DIY Plant Care',
      },
      {
        path: '*',
        element: <Navigate to="plant-maintenance" replace />,
        omitFromContactPage: true,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
    seo: {
      title: 'Redhead Green Thumb | Page Not Found',
      description: 'The page you are looking for does not exist.',
      changeFrequency: 'never',
      priority: 0.1,
    },
  },
];

export default routes;
