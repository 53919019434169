import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import routes, { RouteConfig } from '../routes';

interface SEOData {
  title: string;
  description: string;
  canonicalUrl: string;
}

const defaultSEO: SEOData = {
  title: 'Redhead Green Thumb',
  description: 'Your source for plant care and gardening tips.',
  canonicalUrl: 'https://www.redheadgreenthumb.com', // Replace with your actual domain
};

const findMatchingRoute = (path: string, routes: RouteConfig[], parentPath: string = ''): RouteConfig | undefined => {
  for (const route of routes) {
    const fullPath = `${parentPath}/${route.path}`.replace(/\/+/g, '/');
    if (fullPath === path) return route;
    if (route.children) {
      const childMatch = findMatchingRoute(path, route.children, fullPath);
      if (childMatch) return childMatch;
    }
  }
  return undefined;
};

const SEO: React.FC = () => {
  const location = useLocation();
  const [seoData, setSeoData] = useState<SEOData>(defaultSEO);

  useEffect(() => {
    const currentRoute = findMatchingRoute(location.pathname, routes);
    setSeoData(prevData => {
      const baseUrl = defaultSEO.canonicalUrl.endsWith('/') 
        ? defaultSEO.canonicalUrl.slice(0, -1) 
        : defaultSEO.canonicalUrl;
      const path = location.pathname.startsWith('/') 
        ? location.pathname 
        : `/${location.pathname}`;


      const updatedData = {...prevData,
        title: currentRoute?.seo?.title ?? defaultSEO.title,
        description: currentRoute?.seo?.description ?? defaultSEO.description,
        canonicalUrl: `${baseUrl}${path}`,
      };
      
      return updatedData
    });

  }, [location.pathname]);

  return (
    <Helmet>
      <title>{seoData.title}</title>
      <meta name="description" content={seoData.description} />
      <meta property="og:title" content={seoData.title} />
      <meta property="og:description" content={seoData.description} />
      <meta property="og:url" content={seoData.canonicalUrl} />
      <link rel="canonical" href={seoData.canonicalUrl} />
      <script src="https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js"></script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          "name": seoData.title,
          "description": seoData.description,
          "url": seoData.canonicalUrl,
        })}
      </script>
    </Helmet>
  );
};

export default SEO;